<template>
  <div style="width: 100%;">
    <el-table v-loading="loading" :data="list" border style="width: 100%">
      <el-table-column prop="active.id" label="编号" width="80"></el-table-column>
      <el-table-column prop="active.title" label="活动名称" width="200"></el-table-column>
      <el-table-column prop="active.mode"
                       :formatter="(item)=>item.active ? modeText(item.active.mode) : ''" label="活动类型"
                       width="100"></el-table-column>
      <el-table-column prop="active.fee" label="金额" width="80"></el-table-column>
      <el-table-column label="创建时间">
        <template #default="s">{{ s.row.active.created_at | date}}</template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="代理商" prop="active.agent.title" width="120"></el-table-column>

      <el-table-column fixed="right" label="操作" align="center" width="320">
        <template #default="s">
          <el-button style="margin-right: 1rem" @click="$router.push({
            name:'activeEdit',
            params:$route.params,
            query:{
              id:s.row.active.id
            }
            })"  size="small">编辑活动
          </el-button>
          <el-popconfirm style="margin-right: 1rem" @confirm="acThis(s.row)" title="确认要审核通过？" >
            <el-button slot="reference" type="success" size="small">审核通过</el-button>
          </el-popconfirm>
          <el-popconfirm @confirm="delThis(s.row.active.id)" title="确认要删除？" >
            <el-button slot="reference" type="danger" size="small">删除活动</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>

export default {
  name: "list",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      loading: false,
    }
  },
  mounted() {
    this.loadList();
  },
  methods: {
    delThis(id){
      this.$api.active.del({id}).then(() => {
        this.$message.success("操作成功");
        this.loadList()
      })
    },
    modeText(mode) {
      let str = "";
      switch (parseInt(mode)) {
        case 1:
          str = "通用模式";
          break;
        case 2:
          str = "视频模式";
          break;
        case 3:
          str = "单/多商家极差模式";
          break;
        case 4:
          str = "拼团模式";
          break;
      }
      return str;
    },
    acThis({active}){
      active.agent_judge = true;
      this.$api.active.update(active).then(()=>{
        this.$message.success("操作成功");
        this.loadList();
      })
    },
    pageChange(e) {
      this.page = e;
      this.loadList();
    },
    loadList() {
      this.loading = true;
      this.$api.active.search({
        page: this.page,
        agent_wait_judge:true,
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
      // this.$api.plugin.agent.list({
      //   page: this.page,
      // }).then(res => {
      //   this.loading = false;
      //   this.list = res.list;
      //   this.total = res.total;
      // })
    }
  }
}
</script>

<style scoped>

</style>